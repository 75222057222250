import { request } from "./network";
// 注册-第一步
export function setAccountInfo(data) {
  return request({
    method: "post",
    url: "/web/register/setAccountInfo",
    data,
  });
}

// 检查身份证是否存在
export function checkIdCard(data) {
  return request({
    method: "post",
    url: "/web/auth/checkIdCard",
    data,
  });
}

// 保存个人信息
export function setStudentInfo(data) {
  return request({
    method: "post",
    url: "/web/register/setStudentInfo/step1",
    data,
  });
}

// 前端调用埋点
export function addLog(data) {
  return request({
    method: "post",
    url: "/web/tools/addLog",
    data,
  });
}
