<template>
  <div id="main">
    <el-main>
      <div class="stepBox">
        <comm-step :active="1" :percentage="75" :type="6"></comm-step>
        <self-info
          :selfInfos="selfInfo"
          @getValues="getValue"
          ref="selfInfos"
        ></self-info>
        <div style="width: 100%; padding-top: 20px; text-align: center">
          <el-button :disabled="isDisable" @click="toNext">下一步</el-button>
          <!-- <el-button
            @click="skip()"
            style="background:#fff;border:1px solid #563279;color:#563279"
            >跳过</el-button
          > -->
        </div>
      </div>
    </el-main>
  </div>
</template>

<script>
import commStep from "c/commStep";
import selfInfo from "c/selfInfo";
import { getStep1 } from "r/index/signUp";
import { setStudentInfo } from "r/index/register";

export default {
  name: "StepThree",
  data() {
    return {
      percentage: 50,
      selfInfo: {},
      //个人信息
      selfInfos: {
        //个人信息
        name: "", //学生姓名
        phone: "", //手机号
        sex: 0, //性别 0男  1女
        email: "", //邮箱

        jiguan_code: undefined, //籍贯 籍贯code 【省份id+城市id】
        shengyuandi_code: undefined, //生源code 【省份id+城市id】
        birthday: "", //出生日期，类似20020624
        nation: "", //民族
        political_status: "", //政治面貌
        start_school_date: "", //入学年份
        category: "", //科类[文理科 1 文科 2 理科 3 选科]
        gk_selectsubject: "", //选科科目【格式：3001#3002#3008】
        foreign_language: "", //语种
        address_code: "", //家庭住址省市code【省份id+城市id】
        address: "", //家庭住址
        familyinfo: [
          //家庭信息
          {
            career: "",
            educationdegree: "",
            job: "",
            name: "",
            phone: "",
            relation: "",
            workplace: "",
          },
          {
            career: "",
            educationdegree: "",
            job: "",
            name: "",
            phone: "",
            relation: "",
            workplace: "",
          },
        ],
        schoolprovince: "", //学校省份code（id）通过【获取所有省份列表】获得
        schoolcity: "", //学校城市code（id）通过【获取所有城市列表】获得
        school: "", //学校code（id）通过【获取所有学校列表】获得
        schoolinfo: {
          //学校信息
          contactpeople: "",
          contactphone: "",
          address: "",
          jdcphone: "",
          postcode: "",
          province: "",
          city: "",
          name: "",
        },
        scoreinfo: [
          //成绩信息
          {
            grade: "高一上(期末)",
            gradeIndex: 0,
            scoreItems: [
              {
                subjectIndex: 0,
                myscore: "",
                subjectName: "语文",
                totalscore: "",
              },
              {
                subjectIndex: 1,
                myscore: "",
                subjectName: "数学",
                totalscore: "",
              },
              {
                subjectIndex: 2,
                myscore: "",
                subjectName: "英语",
                totalscore: "",
              },
              {
                subjectIndex: 3,
                myscore: "",
                subjectName: "物理",
                totalscore: "",
              },
              {
                subjectIndex: 4,
                myscore: "",
                subjectName: "化学",
                totalscore: "",
              },
              {
                subjectIndex: 5,
                myscore: "",
                subjectName: "生物",
                totalscore: "",
              },
              {
                subjectIndex: 6,
                myscore: "",
                subjectName: "政治",
                totalscore: "",
              },
              {
                subjectIndex: 7,
                myscore: "",
                subjectName: "历史",
                totalscore: "",
              },
              {
                subjectIndex: 8,
                myscore: "",
                subjectName: "地理",
                totalscore: "",
              },
              {
                subjectIndex: 9,
                myscore: "",
                subjectName: "文理(综合)",
                totalscore: "",
              },
              {
                subjectIndex: 10,
                myscore: "",
                subjectName: "年级排名",
                totalscore: "",
              },
            ],
          },
          {
            grade: "高一下(期末)",
            gradeIndex: 1,
            scoreItems: [
              {
                subjectIndex: 0,
                myscore: "",
                subjectName: "语文",
                totalscore: "",
              },
              {
                subjectIndex: 1,
                myscore: "",
                subjectName: "数学",
                totalscore: "",
              },
              {
                subjectIndex: 2,
                myscore: "",
                subjectName: "英语",
                totalscore: "",
              },
              {
                subjectIndex: 3,
                myscore: "",
                subjectName: "物理",
                totalscore: "",
              },
              {
                subjectIndex: 4,
                myscore: "",
                subjectName: "化学",
                totalscore: "",
              },
              {
                subjectIndex: 5,
                myscore: "",
                subjectName: "生物",
                totalscore: "",
              },
              {
                subjectIndex: 6,
                myscore: "",
                subjectName: "政治",
                totalscore: "",
              },
              {
                subjectIndex: 7,
                myscore: "",
                subjectName: "历史",
                totalscore: "",
              },
              {
                subjectIndex: 8,
                myscore: "",
                subjectName: "地理",
                totalscore: "",
              },
              {
                subjectIndex: 9,
                myscore: "",
                subjectName: "文理(综合)",
                totalscore: "",
              },
              {
                subjectIndex: 10,
                myscore: "",
                subjectName: "年级排名",
                totalscore: "",
              },
            ],
          },
          {
            grade: "高二上(期末)",
            gradeIndex: 2,
            scoreItems: [
              {
                subjectIndex: 0,
                myscore: "",
                subjectName: "语文",
                totalscore: "",
              },
              {
                subjectIndex: 1,
                myscore: "",
                subjectName: "数学",
                totalscore: "",
              },
              {
                subjectIndex: 2,
                myscore: "",
                subjectName: "英语",
                totalscore: "",
              },
              {
                subjectIndex: 3,
                myscore: "",
                subjectName: "物理",
                totalscore: "",
              },
              {
                subjectIndex: 4,
                myscore: "",
                subjectName: "化学",
                totalscore: "",
              },
              {
                subjectIndex: 5,
                myscore: "",
                subjectName: "生物",
                totalscore: "",
              },
              {
                subjectIndex: 6,
                myscore: "",
                subjectName: "政治",
                totalscore: "",
              },
              {
                subjectIndex: 7,
                myscore: "",
                subjectName: "历史",
                totalscore: "",
              },
              {
                subjectIndex: 8,
                myscore: "",
                subjectName: "地理",
                totalscore: "",
              },
              {
                subjectIndex: 9,
                myscore: "",
                subjectName: "文理(综合)",
                totalscore: "",
              },
              {
                subjectIndex: 10,
                myscore: "",
                subjectName: "年级排名",
                totalscore: "",
              },
            ],
          },
          {
            grade: "高二下(期末)",
            gradeIndex: 3,
            scoreItems: [
              {
                subjectIndex: 0,
                myscore: "",
                subjectName: "语文",
                totalscore: "",
              },
              {
                subjectIndex: 1,
                myscore: "",
                subjectName: "数学",
                totalscore: "",
              },
              {
                subjectIndex: 2,
                myscore: "",
                subjectName: "英语",
                totalscore: "",
              },
              {
                subjectIndex: 3,
                myscore: "",
                subjectName: "物理",
                totalscore: "",
              },
              {
                subjectIndex: 4,
                myscore: "",
                subjectName: "化学",
                totalscore: "",
              },
              {
                subjectIndex: 5,
                myscore: "",
                subjectName: "生物",
                totalscore: "",
              },
              {
                subjectIndex: 6,
                myscore: "",
                subjectName: "政治",
                totalscore: "",
              },
              {
                subjectIndex: 7,
                myscore: "",
                subjectName: "历史",
                totalscore: "",
              },
              {
                subjectIndex: 8,
                myscore: "",
                subjectName: "地理",
                totalscore: "",
              },
              {
                subjectIndex: 9,
                myscore: "",
                subjectName: "文理(综合)",
                totalscore: "",
              },
              {
                subjectIndex: 10,
                myscore: "",
                subjectName: "年级排名",
                totalscore: "",
              },
            ],
          },
          {
            grade: "高三上(期末)",
            gradeIndex: 4,
            scoreItems: [
              {
                subjectIndex: 0,
                myscore: "",
                subjectName: "语文",
                totalscore: "",
              },
              {
                subjectIndex: 1,
                myscore: "",
                subjectName: "数学",
                totalscore: "",
              },
              {
                subjectIndex: 2,
                myscore: "",
                subjectName: "英语",
                totalscore: "",
              },
              {
                subjectIndex: 3,
                myscore: "",
                subjectName: "物理",
                totalscore: "",
              },
              {
                subjectIndex: 4,
                myscore: "",
                subjectName: "化学",
                totalscore: "",
              },
              {
                subjectIndex: 5,
                myscore: "",
                subjectName: "生物",
                totalscore: "",
              },
              {
                subjectIndex: 6,
                myscore: "",
                subjectName: "政治",
                totalscore: "",
              },
              {
                subjectIndex: 7,
                myscore: "",
                subjectName: "历史",
                totalscore: "",
              },
              {
                subjectIndex: 8,
                myscore: "",
                subjectName: "地理",
                totalscore: "",
              },
              {
                subjectIndex: 9,
                myscore: "",
                subjectName: "文理(综合)",
                totalscore: "",
              },
              {
                subjectIndex: 10,
                myscore: "",
                subjectName: "年级排名",
                totalscore: "",
              },
            ],
          },
          {
            grade: "最后大考",
            gradeIndex: 5,
            scoreItems: [
              {
                subjectIndex: 0,
                myscore: "",
                subjectName: "语文",
                totalscore: "",
              },
              {
                subjectIndex: 1,
                myscore: "",
                subjectName: "数学",
                totalscore: "",
              },
              {
                subjectIndex: 2,
                myscore: "",
                subjectName: "英语",
                totalscore: "",
              },
              {
                subjectIndex: 3,
                myscore: "",
                subjectName: "物理",
                totalscore: "",
              },
              {
                subjectIndex: 4,
                myscore: "",
                subjectName: "化学",
                totalscore: "",
              },
              {
                subjectIndex: 5,
                myscore: "",
                subjectName: "生物",
                totalscore: "",
              },
              {
                subjectIndex: 6,
                myscore: "",
                subjectName: "政治",
                totalscore: "",
              },
              {
                subjectIndex: 7,
                myscore: "",
                subjectName: "历史",
                totalscore: "",
              },
              {
                subjectIndex: 8,
                myscore: "",
                subjectName: "地理",
                totalscore: "",
              },
              {
                subjectIndex: 9,
                myscore: "",
                subjectName: "文理(综合)",
                totalscore: "",
              },
              {
                subjectIndex: 10,
                myscore: "",
                subjectName: "年级排名",
                totalscore: "",
              },
            ],
          },
        ],
        wishinfo: [
          //国内志愿
          {
            zyunivid: "0",
            zy1id: "0",
            zy2id: "0",
            zy3id: "0",
            zyunivname: "",
            zy1name: "",
            zy2name: "",
            zy3name: "",
          },
          {
            zyunivid: "0",
            zy1id: "0",
            zy2id: "0",
            zy3id: "0",
            zyunivname: "",
            zy1name: "",
            zy2name: "",
            zy3name: "",
          },
          {
            zyunivid: "0",
            zy1id: "0",
            zy2id: "0",
            zy3id: "0",
            zyunivname: "",
            zy1name: "",
            zy2name: "",
            zy3name: "",
          },
        ],
        coopwishinfo: [
          //国外志愿
          {
            zyunivid: "0",
            zy1id: "0",
            zy2id: "0",
            zyunivname: "",
            zy1name: "",
            zy2name: "",
          },
          {
            zyunivid: "0",
            zy1id: "0",
            zy2id: "0",
            zyunivname: "",
            zy1name: "",
            zy2name: "",
          },
        ],
        qjwishinfo: [
          //强基计划志愿
          {
            zyunivid: "0",
            zy1id: "0",
            zy2id: "0",
            zy3id: "0",
            zyunivname: "",
            zy1name: "",
            zy2name: "",
            zy3name: "",
          },
          {
            zyunivid: "0",
            zy1id: "0",
            zy2id: "0",
            zy3id: "0",
            zyunivname: "",
            zy1name: "",
            zy2name: "",
            zy3name: "",
          },
          {
            zyunivid: "0",
            zy1id: "0",
            zy2id: "0",
            zy3id: "0",
            zyunivname: "",
            zy1name: "",
            zy2name: "",
            zy3name: "",
          },
        ],
      },
      isDisable: false//禁用 false-不禁用，true-禁用
    };
  },
  components: {
    commStep,
    selfInfo,
  },
  created() {
    this.$emit("getStatus", 2);
    this.getAccountList();
  },
  methods: {
    // 子组件触发，关闭提示弹框
    closeDialog(value) {
      this.dialogVisibleFu = value;
    },
    //获取个人信息
    getAccountList() {
      getStep1()
        .then((res) => {
          if (res.data.code === 200) {
            let info = res.data.info;
            if (!info.familyinfo) {
              this.selfInfos.familyinfo = [
                //家庭信息
                {
                  career: "",
                  educationdegree: "",
                  job: "",
                  name: "",
                  phone: "",
                  relation: "",
                  workplace: "",
                },
                {
                  career: "",
                  educationdegree: "",
                  job: "",
                  name: "",
                  phone: "",
                  relation: "",
                  workplace: "",
                },
              ];
            }
            if (!info.schoolinfo) {
              this.selfInfos.schoolinfo = {
                //学校信息
                contactpeople: "",
                contactphone: "",
                address: "",
                jdcphone: "",
                postcode: "",
                province: "",
                city: "",
                name: "",
              };
            }
            if (!info.scoreinfo || info.scoreinfo.length <= 0) {
              this.selfInfos.scoreinfo = [
                //成绩信息
                {
                  grade: "高一上(期末)",
                  gradeIndex: 0,
                  scoreItems: [
                    {
                      subjectIndex: 0,
                      myscore: "",
                      subjectName: "语文",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 1,
                      myscore: "",
                      subjectName: "数学",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 2,
                      myscore: "",
                      subjectName: "英语",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 3,
                      myscore: "",
                      subjectName: "物理",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 4,
                      myscore: "",
                      subjectName: "化学",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 5,
                      myscore: "",
                      subjectName: "生物",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 6,
                      myscore: "",
                      subjectName: "政治",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 7,
                      myscore: "",
                      subjectName: "历史",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 8,
                      myscore: "",
                      subjectName: "地理",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 9,
                      myscore: "",
                      subjectName: "文理(综合)",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 10,
                      myscore: "",
                      subjectName: "年级排名",
                      totalscore: "",
                    },
                  ],
                },
                {
                  grade: "高一下(期末)",
                  gradeIndex: 1,
                  scoreItems: [
                    {
                      subjectIndex: 0,
                      myscore: "",
                      subjectName: "语文",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 1,
                      myscore: "",
                      subjectName: "数学",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 2,
                      myscore: "",
                      subjectName: "英语",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 3,
                      myscore: "",
                      subjectName: "物理",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 4,
                      myscore: "",
                      subjectName: "化学",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 5,
                      myscore: "",
                      subjectName: "生物",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 6,
                      myscore: "",
                      subjectName: "政治",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 7,
                      myscore: "",
                      subjectName: "历史",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 8,
                      myscore: "",
                      subjectName: "地理",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 9,
                      myscore: "",
                      subjectName: "文理(综合)",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 10,
                      myscore: "",
                      subjectName: "年级排名",
                      totalscore: "",
                    },
                  ],
                },
                {
                  grade: "高二上(期末)",
                  gradeIndex: 2,
                  scoreItems: [
                    {
                      subjectIndex: 0,
                      myscore: "",
                      subjectName: "语文",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 1,
                      myscore: "",
                      subjectName: "数学",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 2,
                      myscore: "",
                      subjectName: "英语",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 3,
                      myscore: "",
                      subjectName: "物理",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 4,
                      myscore: "",
                      subjectName: "化学",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 5,
                      myscore: "",
                      subjectName: "生物",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 6,
                      myscore: "",
                      subjectName: "政治",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 7,
                      myscore: "",
                      subjectName: "历史",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 8,
                      myscore: "",
                      subjectName: "地理",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 9,
                      myscore: "",
                      subjectName: "文理(综合)",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 10,
                      myscore: "",
                      subjectName: "年级排名",
                      totalscore: "",
                    },
                  ],
                },
                {
                  grade: "高二下(期末)",
                  gradeIndex: 3,
                  scoreItems: [
                    {
                      subjectIndex: 0,
                      myscore: "",
                      subjectName: "语文",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 1,
                      myscore: "",
                      subjectName: "数学",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 2,
                      myscore: "",
                      subjectName: "英语",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 3,
                      myscore: "",
                      subjectName: "物理",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 4,
                      myscore: "",
                      subjectName: "化学",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 5,
                      myscore: "",
                      subjectName: "生物",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 6,
                      myscore: "",
                      subjectName: "政治",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 7,
                      myscore: "",
                      subjectName: "历史",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 8,
                      myscore: "",
                      subjectName: "地理",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 9,
                      myscore: "",
                      subjectName: "文理(综合)",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 10,
                      myscore: "",
                      subjectName: "年级排名",
                      totalscore: "",
                    },
                  ],
                },
                {
                  grade: "高三上(期末)",
                  gradeIndex: 4,
                  scoreItems: [
                    {
                      subjectIndex: 0,
                      myscore: "",
                      subjectName: "语文",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 1,
                      myscore: "",
                      subjectName: "数学",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 2,
                      myscore: "",
                      subjectName: "英语",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 3,
                      myscore: "",
                      subjectName: "物理",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 4,
                      myscore: "",
                      subjectName: "化学",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 5,
                      myscore: "",
                      subjectName: "生物",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 6,
                      myscore: "",
                      subjectName: "政治",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 7,
                      myscore: "",
                      subjectName: "历史",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 8,
                      myscore: "",
                      subjectName: "地理",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 9,
                      myscore: "",
                      subjectName: "文理(综合)",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 10,
                      myscore: "",
                      subjectName: "年级排名",
                      totalscore: "",
                    },
                  ],
                },
                {
                  grade: "最后大考",
                  gradeIndex: 5,
                  scoreItems: [
                    {
                      subjectIndex: 0,
                      myscore: "",
                      subjectName: "语文",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 1,
                      myscore: "",
                      subjectName: "数学",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 2,
                      myscore: "",
                      subjectName: "英语",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 3,
                      myscore: "",
                      subjectName: "物理",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 4,
                      myscore: "",
                      subjectName: "化学",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 5,
                      myscore: "",
                      subjectName: "生物",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 6,
                      myscore: "",
                      subjectName: "政治",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 7,
                      myscore: "",
                      subjectName: "历史",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 8,
                      myscore: "",
                      subjectName: "地理",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 9,
                      myscore: "",
                      subjectName: "文理(综合)",
                      totalscore: "",
                    },
                    {
                      subjectIndex: 10,
                      myscore: "",
                      subjectName: "年级排名",
                      totalscore: "",
                    },
                  ],
                },
              ];
            }
            if (!info.wishinfo) {
              this.selfInfos.wishinfo = [
                //国内志愿
                {
                  zyunivid: "0",
                  zy1id: "0",
                  zy2id: "0",
                  zy3id: "0",
                  zyunivname: "",
                  zy1name: "",
                  zy2name: "",
                  zy3name: "",
                },
                {
                  zyunivid: "0",
                  zy1id: "0",
                  zy2id: "0",
                  zy3id: "0",
                  zyunivname: "",
                  zy1name: "",
                  zy2name: "",
                  zy3name: "",
                },
                {
                  zyunivid: "0",
                  zy1id: "0",
                  zy2id: "0",
                  zy3id: "0",
                  zyunivname: "",
                  zy1name: "",
                  zy2name: "",
                  zy3name: "",
                },
              ];
            }
            if (!info.coopwishinfo) {
              this.selfInfos.coopwishinfo = [
                //国外志愿
                {
                  zyunivid: "0",
                  zy1id: "0",
                  zy2id: "0",
                  zyunivname: "",
                  zy1name: "",
                  zy2name: "",
                },
                {
                  zyunivid: "0",
                  zy1id: "0",
                  zy2id: "0",
                  zyunivname: "",
                  zy1name: "",
                  zy2name: "",
                },
              ];
            }
            if (!info.qjwishinfo) {
              this.selfInfos.qjwishinfo = [
                //强基计划志愿
                {
                  zyunivid: "0",
                  zy1id: "0",
                  zy2id: "0",
                  zy3id: "0",
                  zyunivname: "",
                  zy1name: "",
                  zy2name: "",
                  zy3name: "",
                },
                {
                  zyunivid: "0",
                  zy1id: "0",
                  zy2id: "0",
                  zy3id: "0",
                  zyunivname: "",
                  zy1name: "",
                  zy2name: "",
                  zy3name: "",
                },
                {
                  zyunivid: "0",
                  zy1id: "0",
                  zy2id: "0",
                  zy3id: "0",
                  zyunivname: "",
                  zy1name: "",
                  zy2name: "",
                  zy3name: "",
                },
              ];
            }
            if (!info.start_school_date) {
              this.selfInfos.start_school_date = "";
            }
            if (!info.schoolprovince) {
              this.selfInfos.schoolprovince = "";
            }
            if (!info.schoolcity) {
              this.selfInfos.schoolcity = "";
            }
            if (!info.school) {
              this.selfInfos.school = "";
            }
            let newData = Object.assign(this.selfInfos, res.data.info);
            this.selfInfo = newData;
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //点击下一步
    toNext() {
      this.$refs.selfInfos.getFormData();
    },
    getValue(val) {
      if(this.isDisable){
        return;
      }
      this.isDisable = true;
      setStudentInfo(val)
        .then((res) => {
          if (res.data.code === 200) {
            this.isDisable = false;
            window.localStorage.setItem("applicationstatus", "1");
            this.$router.replace({ name: "StepFour" });
          } else {
            this.isDisable = false;
            this.$message.error(res.data.message);
          }
        })
        .catch((err) => {
          this.isDisable = false;
          console.log(err);
        });
    },

    // // 跳过
    // skip() {
    //   this.$router.replace({ name: "StepFour" });
    // },
  },
};
</script>

<style scoped lang="scss">
#main {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  .el-main {
    max-width: 1300px;
    min-width: 1124px;
    margin: 26px auto;
    padding: 0 50px;
    height: auto;
  }
  .stepBox {
    align-items: center;
    height: auto;
    background: #fff;
    margin: auto;
    padding: 50px;
  }
}
::v-deep .el-button {
  background: #563279;
  color: #fff;
}
</style>
